import React, { useState } from "react";
import { Link } from "gatsby";
import useWindowDimensions from "../components/hooks/useWindowDimensions";
import Img from "gatsby-image";
import Layout from "../components/layout";

import "../styles/templates/index.sass";

const Home = ({ pageContext, location }) => {
  console.log(pageContext);
  const { slugref } = pageContext;
  const { home } = pageContext.data.data;
  const { innerHeight, innerWidth } = useWindowDimensions();
  const [toggle, setToggle] = useState(false);

  if (typeof window === `undefined`) return <></>;
  return (
    <Layout
      location={location}
      data={home}
      toggle={toggle}
      setToggle={setToggle}
      slugref={slugref}
    >
      <Link
        className="niel"
        to={`/${home.locale !== "de" ? `${home.locale}/` : ""}${
          home.slug[0].aboutme
        }`}
      >
        <Img className="nielImage" fluid={home.images[2].fluid} alt="" />
      </Link>
      <section className="pageOption" style={{ height: innerHeight - 109 }}>
        <Link
          to={`/${home.locale !== "de" ? `${home.locale}/` : ""}${
            home.slug[0].portfolio
          }`}
          href="#"
          className="option"
          data-inf="portfolio"
          style={{
            width: `${0.5 * innerWidth +
              324.7543 +
              (0.725 * innerHeight - 456.75)}px`,
            left: 0,
          }}
          data-sal="fade"
          data-sal-delay="0"
          data-sal-easing="ease"
          data-sal-duration="2000"
        >
          <Img className="pageImage" fluid={home.images[1].fluid} />
        </Link>
        <Link
          to={`/${home.locale !== "de" ? `${home.locale}/` : ""}${
            home.slug[0].contact
          }`}
          className="option"
          data-inf={home.slug[0].contact}
          style={{
            width: `${0.5 * innerWidth +
              324.7543 +
              (0.725 * innerHeight - 456.75)}px`,
            right: 0,
          }}
          data-sal="fade"
          data-sal-delay="0"
          data-sal-easing="ease"
          data-sal-duration="2000"
        >
          <Img className="pageImage" fluid={home.images[0].fluid} alt="" />
        </Link>
      </section>
    </Layout>
  );
};

export default Home;

// export const query = graphql`
//   query {
//     imageOne: file(relativePath: { eq: "one.jpg" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//     imageTwo: file(relativePath: { eq: "two.jpg" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//   }
// `;
